var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#fff","overflow":"hidden"}},[_c('div',{staticClass:"head"},[_c('van-icon',{attrs:{"size":"19","name":"arrow-left"},on:{"click":_vm.goBack}}),_c('div',{staticClass:"text"},[_vm._v("会员等级说明")])],1),_c('div',{staticClass:"swiper"},[_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.imgArr),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('div',{staticClass:"slide-1"},[_c('img',{attrs:{"src":item.bgurl,"alt":""}}),_c('div',{staticClass:"top"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"left-one"},[_vm._v(_vm._s(item.text))]),_c('div',{staticClass:"left-two"},[_vm._v(_vm._s(item.level))])]),_c('div',{staticClass:"right"},[_c('img',{attrs:{"src":item.url,"alt":""}})])]),_c('div',{staticClass:"bottom",style:({ backgroundColor: item.color })},[_c('span',{staticStyle:{"margin-right":"15px"}},[_vm._v(_vm._s(item.textare))]),_c('span',[_vm._v(_vm._s(item.current))])])])])}),0)])]),_c('div',{staticClass:"minimg"},[_c('div',[_c('div',{class:{
          bg: true,
          bronze: this.number == 0,
          gold: this.number == 1,
          diamond: this.number == 2,
        }},[_c('div',{class:{
            bronze: this.number == 0,
            gold: this.number == 1,
            diamond: this.number == 2,
          }},[_c('div',{staticClass:"icon"},[_vm._v("S")])])]),_c('div',{staticClass:"text"},[_vm._v("佣金提升")])]),_c('div',[_c('div',{class:{
          bg: true,
          bronze: this.number == 0,
          gold: this.number == 1,
          diamond: this.number == 2,
        }},[_c('div',{class:{
            bronze: this.number == 0,
            gold: this.number == 1,
            diamond: this.number == 2,
          }},[_c('div',{staticClass:"icon"},[_c('van-icon',{attrs:{"name":"coupon-o"}})],1)])]),_c('div',{staticClass:"text"},[_vm._v("隐藏福利")])]),_c('div',[_c('div',{class:{
          bg: true,
          bronze: this.number == 0,
          gold: this.number == 1,
          diamond: this.number == 2,
        }},[_c('div',{class:{
            bronze: this.number == 0,
            gold: this.number == 1,
            diamond: this.number == 2,
          }},[_c('div',{staticClass:"icon"},[_c('van-icon',{attrs:{"name":"refund-o"}})],1)])]),_c('div',{staticClass:"text"},[_vm._v("邀请返利")])]),_c('div',[_c('div',{class:{
          bg: true,
          bronze: this.number == 0,
          gold: this.number == 1,
          diamond: this.number == 2,
        }},[_c('div',{class:{
            bronze: this.number == 0,
            gold: this.number == 1,
            diamond: this.number == 2,
          }},[_c('div',{staticClass:"icon"},[_c('van-icon',{attrs:{"name":"service-o"}})],1)])]),_c('div',{staticClass:"text"},[_vm._v("专属客服")])]),_c('div',[_c('div',{class:{
          bg: true,
          bronze: this.number == 0,
          gold: this.number == 1,
          diamond: this.number == 2,
        }},[_c('div',{class:{
            bronze: this.number == 0,
            gold: this.number == 1,
            diamond: this.number == 2,
          }},[_c('div',{staticClass:"icon"},[_c('van-icon',{attrs:{"name":"point-gift-o"}})],1)])]),_c('div',{staticClass:"text"},[_vm._v("活动福利")])]),_c('div',{class:{ none: this.number <= 0, felx: true }},[_c('div',{class:{
          bg: true,
          bronze: this.number == 0,
          gold: this.number == 1,
          diamond: this.number == 2,
        }},[_c('div',{class:{
            bronze: this.number == 0,
            gold: this.number == 1,
            diamond: this.number == 2,
          }},[_c('div',{staticClass:"icon"},[_c('van-icon',{attrs:{"name":"award-o"}})],1)])]),_c('div',{staticClass:"text"},[_vm._v("奖励提升")])]),_c('div',{class:{ none: this.number <= 1 }},[_c('div',{class:{
          bg: true,
          bronze: this.number == 0,
          gold: this.number == 1,
          diamond: this.number == 2,
        }},[_c('div',{class:{
            bronze: this.number == 0,
            gold: this.number == 1,
            diamond: this.number == 2,
          }},[_c('div',{staticClass:"icon"},[_c('van-icon',{attrs:{"name":"friends-o"}})],1)])]),_c('div',{staticClass:"text"},[_vm._v("团队奖励")])])]),_c('div',{staticClass:"grade"},[_c('h2',{},[_vm._v("会员等级线程图")]),_c('div',[_vm._v(" 用户成长值达到对应升级门槛，即立即升级。 有效期结束后，用户等级将根据上个有效期内已累计积分重新计算等级。 ")]),_c('div',{staticClass:"slide",staticStyle:{"display":"none"}},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"number"},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("成长值")]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"15px"}},[_vm._v(_vm._s(this.levelData.sliver))]),_c('span',{staticStyle:{"margin-left":"77px"}},[_vm._v(_vm._s(this.levelData.gold))]),_c('span',{staticStyle:{"margin-left":"45px"}},[_vm._v(_vm._s(this.levelData.diamond))])]),_c('div')]),_vm._m(2),_c('div',{staticClass:"grow",staticStyle:{"padding-bottom":"15px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("成长值")]),_c('span',{staticStyle:{"margin-left":"9%"}},[_vm._v(_vm._s(this.levelData.sliver))]),_c('span',{staticStyle:{"margin-left":"28%"}},[_vm._v(_vm._s(this.levelData.gold))]),_c('span',{staticStyle:{"margin-left":"17%"}},[_vm._v(_vm._s(this.levelData.diamond))])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('span',{staticStyle:{"transform":"translateX(233%)"}},[_vm._v("银牌")]),_c('span',{staticStyle:{"transform":"translateX(170px)"}},[_vm._v("金牌")]),_c('span',{staticStyle:{"transform":"translateX(238px)"}},[_vm._v("钻石")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('img',{staticStyle:{"transform":"translate(400%, -6px)"},attrs:{"src":require("../../assets/img/member/组 9319.png"),"alt":""}}),_c('img',{staticStyle:{"transform":"translate(180px, -6px)"},attrs:{"src":require("../../assets/img/member/组 9320.png"),"alt":""}}),_c('img',{staticStyle:{"transform":"translate(260px, -6px)"},attrs:{"src":require("../../assets/img/member/组 9321.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"last"},[_c('div',{staticClass:"last-word"},[_c('span',{staticStyle:{"margin-left":"20%"}},[_vm._v("银牌")]),_c('span',{staticStyle:{"margin-left":"30%"}},[_vm._v("金牌")]),_c('span',{staticStyle:{"margin-left":"22%"}},[_vm._v("钻石")])]),_c('img',{staticClass:"img1",attrs:{"src":require("../../assets/img/member/组 9319.png"),"alt":""}}),_c('img',{staticClass:"img2",attrs:{"src":require("../../assets/img/member/组 9319.png"),"alt":""}}),_c('img',{staticClass:"img3",attrs:{"src":require("../../assets/img/member/组 9319.png"),"alt":""}}),_c('div',{staticClass:"last-line"},[_c('div',{staticStyle:{"background":"#4e514a","width":"27%"}}),_c('div',{staticStyle:{"background":"#fc9e00","width":"38%"}}),_c('div',{staticStyle:{"background":"#4476cb","width":"35%"}})])])
}]

export { render, staticRenderFns }