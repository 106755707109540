<template>
  <div style="background: #fff; overflow: hidden">
    <!--导航栏--->
    <div class="head">
      <van-icon @click="goBack" size="19" name="arrow-left" />
      <div class="text">会员等级说明</div>
    </div>
    <!-- 异形轮播图 star-->
    <div class="swiper">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in imgArr"
            :key="index"
          >
            <div class="slide-1">
              <img :src="item.bgurl" alt="" />
              <div class="top">
                <div class="left">
                  <div class="left-one">{{ item.text }}</div>
                  <div class="left-two">{{ item.level }}</div>
                </div>
                <div class="right">
                  <img :src="item.url" alt="" />
                </div>
              </div>
              <div class="bottom" :style="{ backgroundColor: item.color }">
                <span style="margin-right:15px">{{ item.textare }}</span>
                <span>{{item.current}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 会员等级图 -->
    <div class="minimg">
      <div>
        <div
          :class="{
            bg: true,
            bronze: this.number == 0,
            gold: this.number == 1,
            diamond: this.number == 2,
          }"
        >
          <div
            :class="{
              bronze: this.number == 0,
              gold: this.number == 1,
              diamond: this.number == 2,
            }"
          >
            <div class="icon">S</div>
          </div>
        </div>
        <div class="text">佣金提升</div>
      </div>
      <div>
        <div
          :class="{
            bg: true,
            bronze: this.number == 0,
            gold: this.number == 1,
            diamond: this.number == 2,
          }"
        >
          <div
            :class="{
              bronze: this.number == 0,
              gold: this.number == 1,
              diamond: this.number == 2,
            }"
          >
            <div class="icon"><van-icon name="coupon-o" /></div>
          </div>
        </div>
        <div class="text">隐藏福利</div>
      </div>
      <div>
        <div
          :class="{
            bg: true,
            bronze: this.number == 0,
            gold: this.number == 1,
            diamond: this.number == 2,
          }"
        >
          <div
            :class="{
              bronze: this.number == 0,
              gold: this.number == 1,
              diamond: this.number == 2,
            }"
          >
            <div class="icon"><van-icon name="refund-o" /></div>
          </div>
        </div>
        <div class="text">邀请返利</div>
      </div>
      <div>
        <div
          :class="{
            bg: true,
            bronze: this.number == 0,
            gold: this.number == 1,
            diamond: this.number == 2,
          }"
        >
          <div
            :class="{
              bronze: this.number == 0,
              gold: this.number == 1,
              diamond: this.number == 2,
            }"
          >
            <div class="icon"><van-icon name="service-o" /></div>
          </div>
        </div>
        <div class="text">专属客服</div>
      </div>
      <div>
        <div
          :class="{
            bg: true,
            bronze: this.number == 0,
            gold: this.number == 1,
            diamond: this.number == 2,
          }"
        >
          <div
            :class="{
              bronze: this.number == 0,
              gold: this.number == 1,
              diamond: this.number == 2,
            }"
          >
            <div class="icon"><van-icon name="point-gift-o" /></div>
          </div>
        </div>
        <div class="text">活动福利</div>
      </div>
      <div :class="{ none: this.number <= 0, felx: true }">
        <div
          :class="{
            bg: true,
            bronze: this.number == 0,
            gold: this.number == 1,
            diamond: this.number == 2,
          }"
        >
          <div
            :class="{
              bronze: this.number == 0,
              gold: this.number == 1,
              diamond: this.number == 2,
            }"
          >
            <div class="icon"><van-icon name="award-o" /></div>
          </div>
        </div>
        <div class="text">奖励提升</div>
      </div>
      <div :class="{ none: this.number <= 1 }">
        <div
          :class="{
            bg: true,
            bronze: this.number == 0,
            gold: this.number == 1,
            diamond: this.number == 2,
          }"
        >
          <div
            :class="{
              bronze: this.number == 0,
              gold: this.number == 1,
              diamond: this.number == 2,
            }"
          >
            <div class="icon"><van-icon name="friends-o" /></div>
          </div>
        </div>
        <div class="text">团队奖励</div>
      </div>
    </div>
    <!-- 会员等级线程图 -->
    <div class="grade">
      <h2 style="">会员等级线程图</h2>
      <div>
        用户成长值达到对应升级门槛，即立即升级。
        有效期结束后，用户等级将根据上个有效期内已累计积分重新计算等级。
      </div>
      <!--   -->
      <div class="slide" style="display: none">
        <div class="text">
          <span style="transform: translateX(233%)">银牌</span>
          <span style="transform: translateX(170px)">金牌</span>
          <span style="transform: translateX(238px)">钻石</span>
        </div>
        <div class="line">
          <img
            style="transform: translate(400%, -6px)"
            src="../../assets/img/member/组 9319.png"
            alt=""
          />
          <img
            style="transform: translate(180px, -6px)"
            src="../../assets/img/member/组 9320.png"
            alt=""
          />
          <img
            style="transform: translate(260px, -6px)"
            src="../../assets/img/member/组 9321.png"
            alt=""
          />
        </div>
        <div class="number">
          <span style="font-size: 14px">成长值</span> <span style="margin-left: 15px">{{this.levelData.sliver}}</span>
          <span style="margin-left: 77px">{{this.levelData.gold}}</span>
          <span style="margin-left: 45px">{{this.levelData.diamond}}</span>
        </div>
        <div></div>
      </div>
      <div class="last">
        <div class="last-word">
          <span style="margin-left: 20%">银牌</span>
          <span style="margin-left: 30%">金牌</span>
          <span style="margin-left: 22%">钻石</span>
        </div>
        <img class="img1" src="../../assets/img/member/组 9319.png" alt=""/>
        <img class="img2"  src="../../assets/img/member/组 9319.png" alt=""/>
        <img class="img3"  src="../../assets/img/member/组 9319.png" alt=""/>
        <div class="last-line">
           <div style="background: #4e514a; width: 27%;"></div>
           <div style="background: #fc9e00; width: 38%"></div>
           <div style="background: #4476cb; width: 35%"></div>
        </div>
      </div>
      <!-- {{this.levelData.sliver}}  {{this.levelData.gold}} {{this.levelData.diamond}}-->
      <div class="grow" style="padding-bottom: 15px; margin-top: 10px">
          <span style="font-size: 14px">成长值</span>
          <span style="margin-left: 9%">{{this.levelData.sliver}}</span> 
          <span style="margin-left: 28%">{{this.levelData.gold}}</span>
          <span style="margin-left: 17%">{{this.levelData.diamond}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getVipLevelList } from "@/api/memberOrder";
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
import "swiper/dist/js/swiper.min";
export default {
  data() {
    return {
      mySwiper: null,
      number: 0,
      imgArr: [
        {
          bgurl: require("../../assets/img/member/9200.png"),
          url: require('./img/baiyin.png'),
          text: "已解锁",
          level: "白银会员",
          textare: "当前等级高于该等级",
          color: "#6a6a6a",
        },
        {
          bgurl: require("../../assets/img/member/9202.png"),
          url: require('./img/huangjin.png'),
          text: "当前等级",
          level: "黄金会员",
          textare: "当前积分：567",
          color: "#efb455",
        },
        {
          bgurl: require("../../assets/img/member/9367.png"),
          url: require('./img/zuanshi.png'),
          text: "未解锁",
          level: "钻石会员",
          textare: "成就未解锁",
          color: "#667ea6",
        },
      ],
      levelData: {},
    };
  },
  mounted() {
    this.swipert();
  },
  created() {
    this.$store.commit("setSession", this.$route.query.session || '$2a$10$GV9nB/2eiDsIlZ8DTbDw/OCmHJtxbQKYxqGmJmHpmTbJGKi0kfkx2')
    this.getVipLevelList();
  },
  methods: {
    // 回到上一页
    goBack() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },
    // 获取会员等级列表
    async getVipLevelList() {
      await getVipLevelList().then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          this.levelData = res.data.data;
        }
        // this.levelData.levelName = "白银"
        console.log(this.levelData.levelName, "234");
        if (this.levelData.levelName == "银牌") {
          this.imgArr[0].textare = "当前积分: " + this.levelData.pointsCurrent;
          this.imgArr[1].textare = "成就未解锁";
          this.imgArr[2].textare = "成就未解锁";
          this.imgArr[0].text = "当前等级";
          this.imgArr[1].text = "未解锁";
          this.imgArr[2].text = "未解锁";
          // this.imgArr[0].current =`距离银牌会员还需${Number(this.levelData.upScopeNear)-Number(this.levelData.pointsCurrent)}个积分`
          this.imgArr[0].current =`距离金牌会员还需${Number(this.levelData.upScopeNear)}个积分`
        } else if (this.levelData.levelName == "金牌") {
          this.imgArr[1].textare = "当前积分: " + this.levelData.pointsCurrent;
          this.imgArr[0].textare = "当前等级高于该等级";
          this.imgArr[2].textare = "成就未解锁";
          this.imgArr[1].text = "当前等级";
          this.imgArr[0].text = "已解锁";
          this.imgArr[2].text = "未解锁";
          // this.imgArr[1].current =`距离金牌会员还需${Number(this.levelData.gold)-Number(this.levelData.pointsCurrent)}个积分`
          this.imgArr[1].current =`距离钻石会员还需${Number(this.levelData.upScopeNear)}个积分`
        } else if (this.levelData.levelName == "钻石") {
          this.imgArr[2].textare = "当前积分: " + this.levelData.pointsCurrent;
          this.imgArr[0].textare = "当前等级高于该等级";
          this.imgArr[1].textare = "当前等级高于该等级";
          this.imgArr[2].text = "当前等级";
          this.imgArr[1].text = "已解锁";
          this.imgArr[0].text = "已解锁";
          // this.imgArr[2].current =Number(this.levelData.diamond)>Number(this.levelData.pointsCurrent)?`距离钻石会员还需${Number(this.levelData.diamond)-Number(this.levelData.pointsCurrent)}个积分`:''
          // this.imgArr[2].current =Number(this.levelData.diamond)>Number(this.levelData.pointsCurrent)?`距离钻石会员还需${Number(this.levelData.diamond)-Number(this.levelData.pointsCurrent)}个积分`:''
        }
      });
    },
    swip(swiper) {
      this.number = swiper.activeIndex;
    },
    swipert() {
      this.mySwiper = new Swiper(".swiper-container", {
        direction: "horizontal",
        // loop: true,
        centeredSlides: true,
        spaceBetween: 15,
        observer: true,
        onSlideChangeEnd: this.swip,
        observeParents: true,
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true
        // }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.van-icon-arrow-left {
  color: #fff;
  transform: translate(10px, 33px);
}
.none {
  display: none;
}
.felx {
  align-self: start;
}
.grade {
  background-color: #efefef;
  padding: 12px 20px 0 20px;
  color: #4e514a;
  h2 {
    font-size: 19px;
  }
  .slide {
    padding-top: 14px;
    padding-bottom: 8px;
    .text {
      span {
        display: inline-block;
      }
    }
    .line {
      margin-top: 9px;
      margin-bottom: 7px;
      width: 100%;
      height: 4px;
      background-image: linear-gradient(
        90deg,
        #4e514a 0,
        #4e514a 28%,
        #fc9e00 0,
        #fc9e00 63%,
        #4476cb 0,
        #4476cb
      );
      img {
        width: 20px;
        height: 20px;
      }
    }
    .number {
      font-size: 18px;
      span {
        display: inline-block;
      }
    }
  }
  .last {
    position: relative;
    margin-top: 10px;
    .last-word {
      // display: flex;
      margin-bottom: 12px;
    }
    .img1{
      width: 20px;
      height: 20px;
       position: absolute;
       top: 70%;
       left: 23%;
    }
    .img2{
      width: 20px;
      height: 20px;
       position: absolute;
       top: 70%;
       left: 62%;
    }
    .img3{
      width: 20px;
      height: 20px;
       position: absolute;
       top: 70%;
       left: 90%;
    }
    .last-line {
      // margin: 15px 0;
      display: flex;
      div{
        height: 4px;
        // width: 100%;
      }
    }
  }
}

.minimg {
  display: flex;
  padding: 0 37px 0 38px;
  flex-wrap: wrap;
  div {
    width: 75px;
    .bg {
      width: 34px;
      height: 34px;
      margin-bottom: 12px;
      margin-left: 21px;
      // background: linear-gradient(-180deg, #f6f3ec, #9b8e68);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        color: #fff;
      }
      div {
        width: 24.4px;
        height: 24.4px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .bronze {
        border: #726f6f solid 1.5px;
      }
      .gold {
        border: #fec741 solid 1.5px;
      }
      .diamond {
        border: #7faada solid 1.5px;
      }
    }
    .bronze {
      background: linear-gradient(-180deg, #f6f3ec, #9b8e68);
    }
    .gold {
      background: linear-gradient(-180deg, #ffefcd, #fec741);
    }
    .diamond {
      background: linear-gradient(-180deg, #ebf5fd, #7faada);
    }
    .text {
      text-align: center;
      font-size: 14px;
    }
  }
}

.head {
  height: 164px;
  background: url(../../assets/img/member/110.png) no-repeat;
  background-size: 100%;
  .text {
    color: #e1a885;
    font-size: 21px;
    font-weight: 550;
    transform: translateY(41px);
    text-align: center;
  }
}
.slide-1 {
  overflow: hidden;
  width: 288px;
  height: 140px;
  position: relative;
  .top {
    display: flex;
    justify-content: space-between;
    transform: translate(0px, -140px);
    position: relative;
    .left {
      margin-top: 8px;
      .left-one {
        width: 77px;
        height: 18px;
        background: linear-gradient(-90deg, #2f2f30, #808080);
        font-size: 14px;
        text-align: center;
        border-radius: 0 9px 9px 0;
      }
      .left-two {
        color: #ffffff;
        font-size: 24px;
        margin-left: 15px;
        margin-top: 16px;
      }
    }
    /deep/ .right {
      position: absolute;
      right: 0;
      top: 0;
      img {
        width: 90px !important;
        height: 90px !important;
      }
    }
  }
  .bottom {
    width: 100%;
    height: 26px;
    font-size: 12px;
    line-height: 200%;
    margin-top: 30px;
    padding-left: 10px;
    color: #fff;
    position: absolute;
    bottom: 8px;
    left: 0;
  }
}
.swiper {
  transform: translateY(-40px);
}
.swiper-container {
  margin-top: 0.2rem;

  width: 300px;

  height: 150px;

  overflow: visible !important;

  position: relative;
}

.swiper-container .swiper-wrapper .swiper-slide {
  width: 288px;

  border-radius: 0.12rem;
}

.swiper-container .swiper-wrapper .swiper-slide img {
  width: 100%;

  height: 140px;

  border-radius: 0.12rem;
}

.swiper-container .swiper-wrapper .swiper-slide-prev {
  margin-top: 0.18rem;

  height: 120px !important;
}

.swiper-container .swiper-wrapper .swiper-slide-prev img {
  height: 140px !important;
}

.swiper-container .swiper-wrapper .swiper-slide-next {
  margin-top: 0.18rem;
  height: 120px !important;
}

.swiper-container .swiper-wrapper .swiper-slide-next img {
  height: 140px !important;
}

.swiper-container .swiper-wrapper .swiper-slide-active {
  width: 288px;
}

.swiper-pagination {
  bottom: 0.1rem !important;
}
</style>
